import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import { AuthenticationService } from 'app/auth/service';
import { environment } from 'environments/environment';
import {
  BehaviorSubject,
  of,
  Observable,
  throwError,
  forkJoin,
  combineLatest,
} from 'rxjs';
import {
  switchMap,
  take,
  tap,
  catchError,
  map,
  withLatestFrom,
  mergeMap,
} from 'rxjs/operators';
import { BejovoSzamla, Column, Partner, Penznem } from '../models/torzsek';
import { findByProp } from '../utils';
import { PartnerekService } from './partnerek.service';
import { CikktorzsService } from './cikktorzs.service';
import { PenznemekService } from './penznemek.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Injectable({
  providedIn: 'root',
})
export class BejovoSzamlaService {
  private readonly apiEndpoint = 'bejovoszamla';
  private readonly columnsEndpoint = 'bejovoszamla/columns';
  private readonly listaEndpoint = 'service/helplib/oldal_lista/be';
  private readonly updateEndpoint = 'service/helplib/bizonylat_frissites/be';
  private readonly nyomtatasBeallitasEndpoint = 'service/helplib/nyomtatas_kapcsolo/be';
  private readonly kifizetesHozzaadasEnpoint = 'service/bizonylatlib/bejovo_fizetve';
  private readonly kifizetesVisszaEnpoint = 'service/bizonylatlib/bejovo_nincs_fizetve'

  private lastFetch: Date = new Date();
  private bejovoszamla = JSON.parse(localStorage.getItem('bejovoszamla'));

  private readonly _data = new BehaviorSubject<any[]>(null);
  private readonly _selectedData = new BehaviorSubject<any>(null);
  public data: any[] = [];
  private pageNum: number = 1;
  public readonly streamEnd = new BehaviorSubject<boolean>(false);
  private raktarId = JSON.parse(localStorage.getItem('currentUser'))?.raktar;

  // private state$ = combineLatest([
  //   this._data,
  //   this.partnerekService.data$,
  //   this.cikkService.getElerhetoKeszlet(JSON.parse(localStorage.getItem('currentUser'))?.raktar),
  //   this.penznemekService.data$,
  // ]).pipe(
  //   switchMap(([state, partnerek, cikkek, penznemek]) => {
  //     return state ? of(this.mapBejovoSzamlak(state, partnerek, cikkek, penznemek)) : this.bejovoszamla?.db > 0 ? this.getDBData() : this.fetchData()
  //   })
  // );

  private state$ = combineLatest([
    this._data,
    this.partnerekService.data$,
    //this.cikkService.getElerhetoKeszlet(JSON.parse(localStorage.getItem('currentUser'))?.raktar),
    this.penznemekService.data$,
  ]).pipe(
    switchMap(([state, partnerek, penznemek]) =>
      state ? of(this.mapBejovoSzamlak(state, partnerek, penznemek)) : this.fetchData()
    )
  );

  public data$ = combineLatest([
    this.state$,
    this.authService.currentUser$
  ])
  .pipe(map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe))
  );

  constructor(
    private authService: AuthenticationService,
    private partnerekService: PartnerekService,
    private cikkService: CikktorzsService,
    private penznemekService: PenznemekService,
    private api: ApiService,
    private stateService: StateService,
    private dbService: NgxIndexedDBService
  ) {}

  get columns$(): Observable<Column[]> {
    return this.getColumns();
  }

  // get initData$(): Observable<BejovoSzamla[]> {
  //   return this._data.pipe(
  //     take(1),
  //     switchMap((state: BejovoSzamla[]) =>
  //       state
  //         ? forkJoin([
  //           of(state), 
  //           this.partnerekService.initData$, 
  //           this.cikkService.getElerhetoKeszlet(JSON.parse(localStorage.getItem('currentUser'))?.raktar),
  //           this.penznemekService.initData$])
  //           .pipe(
  //             map(([kimenoSzamlak, partnerek, cikkek, penznemek]) =>
  //               this.mapBejovoSzamlak(kimenoSzamlak, partnerek, cikkek, penznemek)
  //             )
  //           )
  //         : JSON.parse(localStorage.getItem('bejovoszamla'))?.db > 0 ? this.getDBData() : this.fetchData()
  //     ),
  //     withLatestFrom(this.authService.currentUser$),
  //     map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe))
  //   );
  // }

  get initData$(): Observable<BejovoSzamla[]> {
    return this._data.pipe(
      take(1),
      switchMap((state: BejovoSzamla[]) =>
        state
          ? forkJoin([
            of(state), 
            this.partnerekService.initData$, 
            //this.cikkService.getElerhetoKeszlet(JSON.parse(localStorage.getItem('currentUser'))?.raktar),
            this.penznemekService.initData$])
            .pipe(
              map(([kimenoSzamlak, partnerek, penznemek]) =>
                this.mapBejovoSzamlak(kimenoSzamlak, partnerek, penznemek)
              )
            )
          : this.fetchData()
      ),
      withLatestFrom(this.authService.currentUser$),
      map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe))
    );
  }

  add(data: BejovoSzamla, sztornozhato: any): Observable<BejovoSzamla> {
    return this.api.post(this.apiEndpoint, this.toJSON(data)).pipe(
      tap((added: BejovoSzamla) => {
        this.stateService.setStateOnAdd(
          {...added,
           sztornozhato: sztornozhato}, this._data);

        // this.dbService.add('bejovoszamla', {...added,
        //   sztornozhato: sztornozhato}).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  delete(id: number): Observable<BejovoSzamla> {
    return this.api.delete(this.apiEndpoint, id).pipe(
      tap((storno: BejovoSzamla) => {
        this.stateService.setStateOnUpdate(storno, this._data);
        //this.dbService.update('bejovoszamla', storno).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  getById(id: string): Observable<BejovoSzamla> {
    return this.data$.pipe(
      take(1),
      map((state) => {
        // Find the data
        const data =
          state.find((item: BejovoSzamla) => item.id === +id) || null;

        // Update the data
        this._selectedData.next(data);

        // Return the data
        return data;
      }),
      switchMap((data) => {
        if (id === 'new') {
          return of(null);
        }

        if (!data) {
          return throwError('Could not found data with id of ' + id + '!');
        }

        return of(data);
      })
    );
  }

  fetchAndMapDataById(id: number | string): Observable<any> {
    // Find the data in state
    const data = this._data.value.find((item: any) => item.id === +id) || null;

    // Update the data
    if(data.tetelek) {
      this._selectedData.next(data);
    }

    return data.tetelek ? of(data) : forkJoin([
      this.api.get(`${this.apiEndpoint}/${id}`).pipe(
        catchError((err: any) => {
          console.log(err);
          return of(null);
        })
      ),
      this.partnerekService.initData$,
      //this.cikkService.getElerhetoKeszlet(JSON.parse(localStorage.getItem('currentUser'))?.raktar),
      this.penznemekService.initData$,
    ]).pipe(
      map(([bejovoSzamla, partnerek, penznemek]) => {
        const retVal = this.mapBejovoSzamla(bejovoSzamla, partnerek, penznemek)
        this.stateService.setStateOnUpdate(retVal, this._data);
        this._selectedData.next(retVal);
        return retVal;
      }
      )
    );
  }

  update(data: BejovoSzamla, sztornozhato: any): Observable<BejovoSzamla> {
    return this.api
      .put(`${this.apiEndpoint}/${data.id}`, this.toJSON(data))
      .pipe(
        tap((updated: BejovoSzamla) => {
          this.stateService.setStateOnUpdate(
            {...updated,
             sztornozhato: sztornozhato
            }, this._data);

          // this.dbService.update('bejovoszamla', {...updated,
          //   sztornozhato: sztornozhato
          //  }).subscribe();
        }),
        catchError((err: any) => throwError(err))
      );
  }

  getBizonylatValtozas(): any {
    this.lastFetch = new Date();
    const oe = JSON.parse(localStorage.getItem('currentUser')).oe;
    const last = JSON.parse(localStorage.getItem('bejovoszamla'))?.lastFetch;
    return this.api.postBizonylat(`${this.updateEndpoint}/${oe}/${last}`, "")
           .pipe(
            take(1),
            tap(valtozas => {
              if (this._data.value.length == 0) {
                //this.fetchData().subscribe();
              } else {
                if (valtozas.valtozasok.length > 0) {
                  valtozas.valtozasok.forEach((bizonylat) => {
                    const biz = findByProp(this._data.value, "id", bizonylat.id);
                    if (biz) {
                      this.stateService.setStateOnUpdate(bizonylat, this._data);
                    } else {
                      this.stateService.setStateOnAdd(bizonylat, this._data);
                    }
                  });
                }
                if (valtozas.torolt_lista.length > 0) {
                  valtozas.torolt_lista.forEach((bizonylat) => {
                    const biz = findByProp(
                      this._data.value,
                      "id",
                      bizonylat.modul_id
                    );
                    if (biz) {
                      this.stateService.setStateOnDelete(biz, this._data);
                    }
                  });
                }
              }
    
              localStorage.setItem(
                "bejovoszamla",
                JSON.stringify({
                  db: this._data.value.length,
                  lastFetch: this.lastFetch,
                })
              );
            }))
  }

  fetchData(): Observable<BejovoSzamla[]> {
    this.lastFetch = new Date();
    return forkJoin([
      this.api.get(`${this.apiEndpoint}`).pipe(
        // bufferCount(25),
        // concatMap(objs => of(objs)),
        tap((data: any[]) => {
          this._data.next(data);
        }),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
      ),
      this.partnerekService.initData$,
      //this.cikkService.getElerhetoKeszlet(JSON.parse(localStorage.getItem('currentUser'))?.raktar),
      this.penznemekService.initData$,
    ]).pipe(
      map(([bejovoSzamlak, partnerek, penznemek]) => {
        localStorage.setItem('bejovoszamla', JSON.stringify({db: bejovoSzamlak.length, lastFetch: this.lastFetch}));
        return this.mapBejovoSzamlak(bejovoSzamlak, partnerek, penznemek)
      })
    );
  }

  getDBData(): Observable<any> {
    console.log('get bejovoszamlak from indexedDB')
    // return this.dbService.getAll('nyugta').pipe(
    //   tap((data: any[]) => this._data.next(data.sort((a,b) => b.id - a.id))),
    // );
    return forkJoin([
      this.dbService.getAll('bejovoszamla').pipe(
         tap((data: any[]) => this._data.next(data.sort((a,b) => b.id - a.id))),
      ),
      this.partnerekService.initData$,
      //this.cikkService.getElerhetoKeszlet(JSON.parse(localStorage.getItem('currentUser'))?.raktar),
      this.penznemekService.initData$,
    ]).pipe(
      map(([kimenoSzamlak, partnerek, penznemek]) => {
        return this.mapBejovoSzamlak(kimenoSzamlak, partnerek, penznemek)
      })
    );
  }

  private getColumns(): Observable<Column[]> {
    //const columns = JSON.parse(localStorage.getItem('bejovo-szamla_columns'));

    // if(columns) {
    //   return of(columns);
    // } else {
      return this.api.get(this.columnsEndpoint).pipe(
        map((columns: Column[]) => {
          const mappedColumns = [
          ...columns,
          { name: 'szamlasorszam', title: "Szamla sorszám", nullable: true},
          { name: 'tetelek', nullable: true },
        ];
        localStorage.setItem('bejovo-szamla_columns', JSON.stringify(mappedColumns));
        return mappedColumns;
        }),
        catchError((err: any) => {
          console.log(err);
  
          return of([]);
        })
      );
    //}
  }

  private mapBejovoSzamlak(
    bejovoSzamlak: BejovoSzamla[],
    partnerek: Partner[],
    //cikkek: any[],
    penznemek: any[]
  ): BejovoSzamla[] {
    return bejovoSzamlak.map((e: any) => {
      const penznemObj: Penznem = findByProp(
        penznemek,
        'id',
        e.penznem_id
      );
  
      const penznem = penznemObj?.outfix || penznemObj?.prefix;

      let sztornozhato = true;
      // e.tetelek.forEach(tetel => {
      //   if(tetel.bevetelezes_id) {
      //     return
      //   }
      //   if(cikkek?.find(item => tetel.cikk_id == item.id)?.aktualis_mennyiseg <= tetel.mennyiseg) {
      //     sztornozhato = false;
      //   }
      // })
      return {
        ...e,
        partner: findByProp(partnerek, 'id', e.partner_id)?.nev,
        szamla_kelte: new Date(e.szamla_kelte),
        penznem,
        teljesites_datum: new Date(e.teljesites_datum),
        fizetesi_hatarido: new Date(e.fizetesi_hatarido),
        sztornozhato: sztornozhato,
        //tetelek: e.tetelek.map((t: any) => ({ ...t, penznem })),
      }
    });
  }

  private mapBejovoSzamla(
    bejovoSzamla: any,
    partnerek: Partner[],
    //cikkek: any[],
    penznemek: any[]
  ): BejovoSzamla[] {
      const penznemObj: Penznem = findByProp(
        penznemek,
        'id',
        bejovoSzamla.penznem_id
      );
  
      const penznem = penznemObj?.outfix || penznemObj?.prefix;

      let sztornozhato = true;
      // bejovoSzamla.tetelek.forEach(tetel => {
      //   if(tetel.bevetelezes_id) {
      //     return
      //   }
      //   if(cikkek?.find(item => tetel.cikk_id == item.id)?.aktualis_mennyiseg <= tetel.mennyiseg) {
      //     sztornozhato = false;
      //   }
      // })
      return {
        ...bejovoSzamla,
        partner: findByProp(partnerek, 'id', bejovoSzamla.partner_id)?.nev,
        szamla_kelte: new Date(bejovoSzamla.szamla_kelte),
        penznem,
        teljesites_datum: new Date(bejovoSzamla.teljesites_datum),
        fizetesi_hatarido: new Date(bejovoSzamla.fizetesi_hatarido),
        sztornozhato: sztornozhato,
        tetelek: bejovoSzamla.tetelek.map((t: any) => ({ ...t, penznem })),
      }
  }

  private toJSON(data: any): BejovoSzamla {
    return {
      ...data,
      teljesites_datum: new Date(new Date(data.teljesites_datum).setHours(new Date(data.teljesites_datum).getHours() + 3)),
      fizetesi_hatarido: new Date(new Date(data.fizetesi_hatarido).setHours(new Date(data.fizetesi_hatarido).getHours() + 3)),
      cim: JSON.stringify(data.cim),
    };
  }

  addKifizetes(id: any): any {
    return this.api
    .post(`${this.kifizetesHozzaadasEnpoint}/${id}`, '')
    .pipe(
      tap((updated: any) => {
        //this.stateService.setStateOnUpdate(updateRow, this._data);
        //this.getBizonylatValtozas();
      }),
      catchError(() => of(null))
    );
  }

  removeKifizetes(id: any) {
    return this.api
    .post(`${this.kifizetesVisszaEnpoint}/${id}`, '')
    .pipe(
      tap((updated: any) => {
        //this.stateService.setStateOnUpdate(updateRow, this._data);
        //this.getBizonylatValtozas();
      }),
      catchError(() => of(null))
    );
  }

  generatePdf(payload: { id: number; sztorno?: boolean }): Observable<any> {
    return this.api
      .postBizonylat(`${environment.pdfGeneralas}/${this.apiEndpoint}`, payload)
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  sztorno(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id, 'sztornózás').pipe(
      tap((storno: any) => {
        this.stateService.setStateOnUpdate(storno, this._data);
        //this.dbService.update('bejovoszamla', storno).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  updateState(data: any[]): void{
    data.forEach(item => {
      this.stateService.setStateOnUpdate(item, this._data);
    })
  }

  getMennyiseg(): any {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    return this.api.postBizonylat(`service/helplib/ossz_bizonylat/be/${currentUser.id}`, "");
  }

  clearData(): any {
    this.data = [];
  }

  nyomtatasBeallitas(row: any, megjegyzes_nyomtat: number, hivatkozas_nyomtat: number = 0): Observable<any> {
    return this.api
    .post(`${this.nyomtatasBeallitasEndpoint}/${row.id}/${megjegyzes_nyomtat}/${hivatkozas_nyomtat}`, '')
    .pipe(
      tap((updated: any) => {
        const updateRow = {
          ...row,
          megjegyzes_nyomtat: megjegyzes_nyomtat,
        }
        this.stateService.setStateOnUpdate(updateRow, this._data);
      }),
      catchError(() => of(null))
    );
  }
}
