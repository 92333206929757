import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import {
  Arkategoria,
  Cikktorzs,
  PenzugyiBizonylatTetel,
} from 'app/main/pages/torzsek/models/torzsek';
import { FoglalasService } from 'app/main/pages/torzsek/services/foglalas.service';
import { KeszletkezelesTorzs } from 'app/main/pages/torzsek/types/torzsek.types';
import {
  findByProp,
  calcBrutto,
  calcNettoFromAfa,
  calcNetto,
  roundToTwoDecimalPlaces,
} from 'app/main/pages/torzsek/utils';
import { Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil, tap } from 'rxjs/operators';
import { TetelekToolbarService } from './tetelek-toolbar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tetelek-toolbar',
  templateUrl: './tetelek-toolbar.component.html',
  styleUrls: ['./tetelek-toolbar.component.scss'],
  providers: [TetelekToolbarService],
})
export class TetelekToolbarComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('cikkSelect') cikkSelectInput: NgSelectComponent;
  @ViewChild('mennyisegInput') mennyisegInput: ElementRef;
  @ViewChild('cikkKoltseg') cikkInput: ElementRef;
  // Data
  @Input() tetelekControl: AbstractControl;
  @Input() selectedItem: { dataItem: any; rowIndex: number };
  @Input() afakulcsok: any[] = [];
  @Input() arkategoriak?: Arkategoria[];
  @Input() cikkek: any[];
  @Input() mennyisegiEgysegek: any[];
  @Input() torzs: KeszletkezelesTorzs;
  @Input() minMennyiseg: number = 0;
  @Input() arkategoriaId: number = 3;
  @Input() layout: 'basic' | 'ertekesites' = 'basic';
  @Input() customToolbarTemplate: TemplateRef<any>;
  @Input() helyesbites?: boolean;
  @Input() selectedPenznem: any;
  @Input() arfolyamok: any;
  @Input() kedvezmenyPartner: any;
  @Input() ertekesitesForm: any;
  @Input() helyesbitesCikk: any;
  @Input() mennyisegByCikk: any[];
  @Input() cikktorzs: any[];

  // Output events
  @Output() resetSelection = new EventEmitter<void>();
  @Output() filterChange = new EventEmitter<string>();
  @Output() fetchData = new EventEmitter<void>();
  @Output() valid = new EventEmitter<void>();

  formGroup: FormGroup;
  bejovoSzamlaForm: FormGroup;
  searchMode: boolean = false;
  mennyisegControl: AbstractControl;
  firstEditableIndex: number;
  beszerar_update: boolean = false;
  selectedCikk: any;
  editMode: boolean;
  foglaltMennyiseg: number = 0;
  routeErtekesites: string = '';
  kedvezmenyInit: number = 0;
  elerhetoMennyiseg: number;
  firstload: boolean = true;

  isMennyisegValid: boolean = false;

  // Add a map to store the initial `aktualis_mennyiseg` values
  private initialMennyisegByCikk: Map<number, number> = new Map();

  //árkategoriák
  private arakByArkategoria: {arkategoria_id: number, ar: number}[];

  private initialMennyiseg: number;
  private szamlazva: number;

  get formValue(): any {
    return this.formGroup.getRawValue();
  }

  get arkategoriaSzazalek(): number {
    return findByProp(this.arkategoriak, 'id', this.arkategoriaId)?.ertek;
  }

  get afakulcs(): number {
    return this.formGroup.get('afakulcs')?.value;
  }

  get afaKulcsId():AbstractControl {
    return this.formGroup.get('afakulcs');
  }

  get nettoEgysegar(): AbstractControl {
    return this.formGroup.get('netto_egysegar');
  }

  get nettoOsszesen(): AbstractControl {
    return this.formGroup.get('netto_ar');
  }

  get kedvezmeny(): AbstractControl {
    return this.formGroup.get('kedvezmeny');
  }

  get afaEgysegar(): AbstractControl {
    return this.formGroup.get('afa_egysegar');
  }

  get bruttoEgysegar(): AbstractControl {
    return this.formGroup.get('brutto_egysegar');
  }

  get mennyiseg(): number {
    //return this.formGroup.get('mennyiseg')?.value;
    return this.mennyisegControl?.value;
  }

  get tetelek(): PenzugyiBizonylatTetel[] {
    return this.tetelekControl?.value || [];
  }

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private service: TetelekToolbarService,
    private foglalasService: FoglalasService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.routeErtekesites = this.route.url.split("/")[2];
    this.editMode = !this.route.url.split("/")[3]?.includes('add');
    this.formGroup = this.service.createTetelForm(
      this.torzs,
      {
        data: this.selectedItem?.dataItem,
        helyesbites: this.helyesbites,
      }
    );

    this.bejovoSzamlaForm = this.service.createBejovoSzamlaForm();
    // Helyesbítés esetén az uj_mennyiség, míg normál esetben a mennyiség mezőbe ír a user
    this.mennyisegControl = this.helyesbites
      ? this.formGroup.get('uj_mennyiseg')
      : this.formGroup.get('mennyiseg');
 
    // Ha helyesbítés akkor a már meglévő tételeknek csak a mennyisége módosítható, egyébként minden
    this.firstEditableIndex = this.helyesbites ? this.tetelek.length : 0;

    // Pénzügyi bizonylatok esetén VAGY Szállítólevél esetén, kivéve bevételezés
      this.calcOnMennyisegChange();

    if(this.torzs == 'bevetelezes') {
      this.calcOnBeszerarChange();
      //this.calcOnBeszerarOsszegChange();
    }

    this.calcOnNettoChange();
    this.calcOnAfaChange();
    this.calcOnBruttoChange();
    this.calcOnAfakulcsChange();

    if(this.torzs == 'bejovo-szamla') {
      this.calcOnNettoChanges();
      this.calcOnBruttoChanges();
    }

    if(this.torzs != 'bejovo-koltseg') {
      this.calcOnMeChange();
      this.formGroup.patchValue({
        cikk_id: 0
      })
    }

    this.calcOnNettoOsszesenChange();
    this.calcOnKedvezmenyChange();

    this.fetchData.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['cikkek']) {
    //   this.cikkek.push({ cikkszam: 'vev001', id: 1234});
    // }
    if ('selectedItem' in changes && this.formGroup) {
      // Fill data
      if (this.selectedItem?.dataItem) {
        //console.log('selected', this.selectedItem)
        // Retrieve the cikktorzs element to get all data
        //this.cikkek = [...this.cikkek, {cikknev: this.selectedItem?.dataItem.cikknev ,cikkszam: 'vev001', id: 1}];
        let cikk = this.getCikkById(this.selectedItem.dataItem.cikk_id);
        console.log('cikk', cikk);
        this.selectedCikk = cikk;

        const inSelect = findByProp(this.cikkek, 'id', this.selectedItem.dataItem.cikk_id);

        if(!inSelect && this.cikkek) {
          this.cikkek.push(cikk);
          this.cikkek = [...this.cikkek];
        }

        if(this.torzs != 'bevetelezes' && this.torzs != 'egyeb-raktari-bevet' && this.torzs != 'egyeb-raktari-kivet' && this.torzs != 'bejovo-szamla') {
          this.calcArak(cikk);
        }

        // Update toolbar form
        //this.formGroup.reset();
        this.fillToolbarOnEdit(this.createEditData(cikk));

        //Fill bejovo form
        if(this.torzs == 'bejovo-szamla') {
          this.bejovoSzamlaForm.patchValue({
            beszerzesi_ar: cikk.beszerzesi_ar,
            utolso_ar: this.selectedItem.dataItem.netto_fix,
            netto_fix: this.selectedItem.dataItem.netto_fix,
            fix_ar: this.selectedItem.dataItem.fix_ar,
            aktualis_mennyiseg: cikk.aktualis_mennyiseg,
          }, {emitEvent: false});
          this.calcOnNettoFixChange();
        }

        // Focus input
        this.focusMennyisegInput();

        // Update foglalas table
        this.updateFoglalasTable();
      }

      // Form reset
      else {
        this.formGroup.reset(this.service.createTetelForm(this.torzs), {
          emitEvent: false,
        });
      }

      // Reset search and show toolbar
      this.hideSearch();
    }

    // Arkategoria change event
    if ('arkategoriaId' in changes && this.formGroup) {
      // If cikk is selected in toolbar
      if (this.formGroup.get('cikk_id')?.value) {
        // Eladási ár
        const eladasiAr = this.getCikkById(
          this.formGroup.get('cikk_id')?.value
        )?.beszerzesi_ar;


        const cikk = this.getCikkById(
          this.formGroup.get('cikk_id')?.value
        );

        console.log('arkategoria_id', this.arkategoriaSzazalek)

        if(this.arkategoriaId == 3) {
          this.formGroup.patchValue({
            netto_egysegar: cikk?.netto_fix ? this.changeValuta(cikk.netto_fix) : 0,
          });

          // Mark as dirty to let btn enabled
          this.formGroup.markAsDirty();
        }
        else if(this.arkategoriaId == 20) {
          if(cikk.webshop_ar) {
            this.formGroup.patchValue({
              brutto_egysegar: this.changeValuta(cikk.webshop_ar)
            });
          } else {
            this.formGroup.patchValue({
              netto_egysegar: cikk.netto_fix ? this.changeValuta(cikk.netto_fix) : 0
            });
          }
          // Mark as dirty to let btn enabled
          this.formGroup.markAsDirty();
        }
        else if(cikk?.fix) {
          // Update form
          this.formGroup.patchValue({
            netto_egysegar: this.changeValuta(cikk.fix_ar),
          });

          // Mark as dirty to let btn enabled
          this.formGroup.markAsDirty();
        } else if(this.arkategoriaId == 1) {
          // Update form
          // this.formGroup.patchValue({
          //   netto_egysegar: this.formGroup.get('netto_egysegar').value,
          // });
        
          // Mark as dirty to let btn enabled
          this.formGroup.markAsDirty();
        } else {
          // Update form
          this.formGroup.patchValue({
              netto_egysegar:
                this.changeValuta(eladasiAr * (1 + (this.arkategoriaSzazalek || 0) / 100)),
          });
          
          // Mark as dirty to let btn enabled
          this.formGroup.markAsDirty();
        }
      }
    }

    //Penznem change event
    if('selectedPenznem' in changes) {
      this.formGroup?.patchValue({
        netto_egysegar:
        this.selectedPenznem?.id == 1 ? Math.round(this.changeValuta(this.calcByArkategoria(this.formValue))) :
        this.changeValuta(this.calcByArkategoria(this.formValue))
      })
    }

    //Helyesbítés cikk select
    if('helyesbitesCikk' in changes) {
      const cikk = findByProp(this.cikkek, 'id', this.helyesbitesCikk?.dataItem.cikk_id);
      if(cikk) {
        //this.onCikkSelect(cikk);
        this.patchHelyesbitoCikk(this.helyesbitesCikk?.dataItem);
      } else {
        const cikkhely = findByProp(this.cikktorzs, 'id', this.helyesbitesCikk?.dataItem.cikk_id);

        if(cikkhely && this.cikkek) {
          this.cikkek.push(cikkhely);
          this.cikkek = [...this.cikkek];
          this.patchHelyesbitoCikk(this.helyesbitesCikk?.dataItem);
          //this.onCikkSelect(cikkhely, true);
          this.calcAktualisMennyisegHelyesbites();
          //this.fillToolbarOnEdit(cikkhely);
        }
      }
    }

    if('mennyisegiEgysegek' in changes) {
      if(this.mennyisegiEgysegek) {
        this.mennyisegiEgysegek = this.mennyisegiEgysegek.filter(me => me.id != 0);
      }
    }

    //Partner change event
    if('ertekesitesForm' in changes) {
      this.foglalasService.cikkFoglalasok$.subscribe(resp => {
        this.foglaltMennyiseg = 0;
        resp.forEach(foglalas => {
          if(foglalas.pid == this.ertekesitesForm.get('partner_id').value) {
            this.foglaltMennyiseg += (foglalas.mennyiseg - foglalas.kiszamlazva);
          }
        });
      })
    }

    //Kedvezmeny change event 
    if('kedvezmenyPartner' in changes) {
      if(this.formGroup?.get('kedvezmeny')) {
        this.formGroup.patchValue({
          kedvezmeny: this.kedvezmenyPartner?.kedvezmeny
        });
        this.kedvezmenyInit = this.kedvezmenyPartner?.kedvezmeny;
      }

      if(this.torzs == 'kimeno-szamla' && this.routeErtekesites != 'ertekesites' || this.torzs == 'elolegszamla') {
        this.foglalasService.cikkFoglalasok$.subscribe(resp => {
          this.foglaltMennyiseg = 0;
          resp.forEach(foglalas => {
            if(foglalas.pid == this.kedvezmenyPartner?.id) {
              this.foglaltMennyiseg += (foglalas.mennyiseg - foglalas.kiszamlazva);
            }
          });
        })
      } else if (this.routeErtekesites == 'ertekesites') {
      }

      this.mennyisegControl?.updateValueAndValidity({ onlySelf: false, emitEvent: true })
    }

    //cikkek change
    if('cikkek' in changes) {
      console.log('cikkek', this.cikkek)
      if (this.cikkek) {
        this.cikkek = [...this.cikkek];
      }
      if(this.routeErtekesites == 'ertekesites' && this.firstload) {
        this.cikkSelectInput?.focus();
        this.firstload = false;
      } 
      else if(this.torzs == 'szallitolevel') {
        this.calcAktualisMennyiseg();
      }
    }

    //mennyisegByCikk change
    if('mennyisegByCikk' in changes) {

    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  quickAddToList(e: KeyboardEvent): void {
    if (e.key === 'Enter' && this.formGroup.valid) {
      this.onAdd();

      // Focus the cikk select input again after row added to list
      if(this.formValue.brutto_ar) {
        setTimeout(() => {
          this.cikkSelectInput.focus();
        }, 0);
      }
    }
    else if (e.key === 'Escape' && this.formGroup.valid) {
      this.formGroup.reset();
      this.bejovoSzamlaForm.reset();

      // Focus the cikk select input again after row added to list
      setTimeout(() => {
        this.cikkSelectInput.focus();
      }, 0);
    }
  }

  onCikkSelect(cikk: any, fetchOption?: boolean): void {
    if(!fetchOption && !((this.torzs == 'kimeno-szamla' || this.torzs == 'nyugta') && this.helyesbites)) {
      //this.fetchData.emit();
    }

    if(this.torzs != 'bevetelezes' && this.torzs != 'egyeb-raktari-bevet' && this.torzs != 'egyeb-raktari-kivet' && this.torzs != 'bejovo-szamla') {
      this.calcArak(cikk);
    }

    this.selectedCikk = cikk;
    this.foglaltMennyiseg = 0;

    if(this.torzs == 'bejovo-szamla') {
      this.bejovoSzamlaForm?.patchValue({
        beszerzesi_ar: cikk.beszerzesi_ar,
        utolso_ar: cikk.fix_ar,
        netto_fix: cikk.netto_fix,
        fix_ar: cikk.fix_ar,
        aktualis_mennyiseg: cikk.aktualis_mennyiseg,
        me: cikk.me_text.split('/')[0]
      }, {emitEvent: false})
    }

    if(this.formGroup?.get('kedvezmeny')) {
      this.formGroup.patchValue({
        kedvezmeny: this.kedvezmenyInit
      })
    }

    const afakulcs = this.getAfakulcs(cikk.afakulcs_id);
    const netto_egysegar = this.selectedPenznem?.id != 1 ? 
        this.changeValuta(this.calcNettoEgysegarByArkategoria(cikk)) : 
        this.calcNettoEgysegarByArkategoria(cikk);

    const netto_ar = Math.round(netto_egysegar);
    const afa_ar = Math.round((netto_ar * (afakulcs / 100))) || 0;
    const brutto_ar = netto_ar + afa_ar;

    if(this.arkategoriaId == 20) {
      if(cikk.webshop_ar) {
        this.formGroup.patchValue({
          ...cikk,
          cikk_id: cikk.id,
          afakulcs,
          beszerzesi_ar_osszeg: cikk.beszerzesi_ar,
          mennyiseg: null,
          uj_mennyiseg: 1,
          mennyisegi_egyseg: cikk.me_id,
          brutto_egysegar: cikk.webshop_ar
        });
      } else {
        const netto_egysegar = cikk.netto_fix ? cikk.netto_fix : 0;
        this.formGroup.patchValue({
          ...cikk,
          cikk_id: cikk.id,
          afakulcs,
          beszerzesi_ar_osszeg: cikk.beszerzesi_ar,
          mennyiseg: null,
          uj_mennyiseg: 1,
          mennyisegi_egyseg: cikk.me_id,
          netto_egysegar: cikk.netto_fix ? cikk.netto_fix : 0,
          brutto_egysegar: calcBrutto(netto_egysegar, afakulcs)
        });
      }
    } else {
      if(cikk.fix) {
        this.formGroup.patchValue({
          ...cikk,
          cikk_id: cikk.id,
          afakulcs,
          beszerzesi_ar_osszeg: cikk.beszerzesi_ar,
          mennyiseg: null,
          uj_mennyiseg: 1,
          mennyisegi_egyseg: cikk.me_id,
          netto_egysegar: cikk.netto_fix ? cikk.netto_fix : 0
        });
      }
      else {
        this.formGroup.patchValue({
          ...cikk,
          cikk_id: cikk.id,
          afakulcs,
          beszerzesi_ar_osszeg: cikk.beszerzesi_ar,
          mennyiseg: null, //this.helyesbites ? 0 : 1,
          uj_mennyiseg: 1,
          mennyisegi_egyseg: cikk.me_id,
          netto_egysegar, // Ha van
          netto_ar, // Ha van
          afa_ar, // Ha van
          brutto_ar, // Ha van
        });
      }
    }

    this.foglalasService.selectFoglalasCikk(cikk.id);

    this.focusMennyisegInput();
  }

  hideSearch(): void {
    this.searchMode = false;
    this.filterChange.emit('');
  }

  showSearch(): void {
    this.searchMode = true;

    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 0);
  }

  onResetSelection(): void {
    this.resetSelection.emit();

    if(this.torzs == 'bejovo-szamla') {
      this.bejovoSzamlaForm.reset();
    }
  }

  onKeyup(e: any): void {
    this.filterChange.emit(e.target.value);
  }

  onAdd(): void {
    if(this.torzs != 'bevetelezes' && this.torzs != 'egyeb-raktari-bevet' && this.torzs != 'egyeb-raktari-kivet' && !this.formValue.brutto_ar) {
      return;
    }

    if(this.torzs != 'bevetelezes' && this.torzs != 'egyeb-raktari-bevet' && this.torzs != 'egyeb-raktari-kivet') {
      this.formGroup.patchValue({
        arkategoria_id: this.getTetelArkategoria(),
        arkategoria: findByProp(this.arkategoriak, 'id', this.getTetelArkategoria())?.nev
      })
    }

    if((this.formValue.netto_egysegar < this.formValue.beszerzesi_ar) && this.torzs != 'bejovo-szamla') {
      //this.service.showTulcsordultMennyisegToast('beszerar');
      return;
    }

    const formValue = this.torzs == 'bevetelezes' ? {...this.service.createListElement(
      this.torzs,
      this.formValue,
      this.mennyisegiEgysegek,
      this.helyesbites,
      this.selectedPenznem
    ), 
    beszerar_frissit: this.calcKulonbsegSzazalek(this.selectedCikk.beszerzesi_ar, this.formValue.beszerzesi_ar) > 0 ? true : false,
    regi_beszerzesi_ar: this.selectedCikk.beszerzesi_ar
  } : 
    this.torzs == 'bejovo-szamla' ? 
    {...this.service.createListElement(
      this.torzs,
      this.formValue,
      this.mennyisegiEgysegek,
      this.helyesbites,
      this.selectedPenznem
    ),
    beszerar_frissit: this.selectedCikk.cikkcsoport_id == 57 ? false : this.calcKulonbsegSzazalek(this.selectedCikk.beszerzesi_ar, this.formValue.netto_egysegar) > 0 ? true : false,
    netto_fix: this.bejovoSzamlaForm.get('netto_fix').value,
    fix_ar: this.bejovoSzamlaForm.get('fix_ar').value
    } :
    this.service.createListElement(
      this.torzs,
      this.formValue,
      this.mennyisegiEgysegek,
      this.helyesbites,
      this.selectedPenznem
    );
    this.kedvezmenyInit = this.formValue.kedvezmeny;
    console.log('formValue', formValue)

    if(formValue.id == 1 && this.torzs == 'elolegszamla') {
      this.formGroup.get('cikk_id').disable();
      console.log('form', formValue);
    }

    if(formValue.mennyiseg == 0 && !this.helyesbites) {
      this.service.showTulcsordultMennyisegToast('nulla');
      return;
    }

    if(this.torzs == 'szallitolevel') {
      if(!this.checkInput(formValue)) {
        this.service.showTulcsordultMennyisegToast('szallitolevel')
        return;
      }

      if(formValue.mennyiseg < 0 && this.checkIfNotInList(formValue.cikk_id)) {
        this.service.showTulcsordultMennyisegToast('nincs_listaban')
        return;
      }
    }

    const listIndex = this.service.getIndexInList(formValue, this.tetelek);

    // Update
    if (this.selectedItem && !this.helyesbites) {
      const newState = this.tetelek.map((e: any, index: number) =>
        index === this.selectedItem.rowIndex ? formValue : e
      );

      if(this.torzs == 'bevetelezes') {
        this.tetelekControl.patchValue(newState);
        //this.tetelekControl.patchValue(this.tetelek.concat(formValue));
      }
      else if(this.torzs == 'szallitolevel') {
        this.tetelekControl.patchValue(newState);
      }
      else {
        this.tetelekControl.patchValue(newState);
      }

    }

    // Already in list
    else if (listIndex > -1) {
      const newState = this.service.updateListItem(
        listIndex,
        formValue,
        this.mennyisegiEgysegek,
        this.tetelek,
        this.torzs,
        this.torzs === 'egyeb-raktari-kivet' // Csak ebben a törzsben van eladási árként küldve
      );

      if(this.torzs == 'bevetelezes' || this.torzs === 'szallitolevel' || (this.torzs === 'kimeno-szamla' && this.helyesbites)) {
        //this.tetelekControl.patchValue(newState);
        this.tetelekControl.patchValue(this.tetelek.concat(formValue));
      } else {
        this.tetelekControl.patchValue(newState);
      }
    }

    // Add
    else {
      if(this.torzs == 'bevetelezes') {
        this.tetelekControl.patchValue(this.tetelek.concat(formValue));
      } else {
        this.tetelekControl.patchValue(this.tetelek.concat(formValue));
      }
    }

    // Reset form
    this.formGroup.reset(this.service.createTetelForm(this.torzs), {
      emitEvent: false,
    });

    if(this.torzs == 'bejovo-szamla') {
      this.bejovoSzamlaForm.reset();
    }

    this.beszerar_update = false;

    // Mark tetelek as dirty
    this.tetelekControl.markAsDirty();

    // Reset selection Ref
    this.resetSelection.emit();
    this.valid.emit();

    // Clear foglalas table
    this.foglalasService.clearFoglalasCikk();

    // Focus the cikk select input again after row added to list
    if(this.torzs != 'bejovo-koltseg') {
      setTimeout(() => {
        this.cikkSelectInput.focus();
      }, 0);
    } 
    else if(this.torzs == 'bejovo-koltseg'){
      setTimeout(() => {
        this.cikkInput.nativeElement.focus();
      }, 0);
    }

  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();

    // Creating and array of space saperated term and removinf the empty values using filter
    let splitTerm = term.split(' ').filter((t) => t);

    let isWordThere = [];

    // Pushing True/False if match is found
    splitTerm.forEach((arr_term) => {
      let cikknev = item['cikknev']?.toLowerCase();
      let cikkszam = item?.['cikkszam']?.toLowerCase() || '';
      let vonalkod = item?.['vonalkod']?.toLowerCase() || '';
      let leiras = item['leiras']?.toLowerCase();
      isWordThere.push(
        cikknev.indexOf(arr_term?.toLowerCase()) != -1 ||
          cikkszam.indexOf(arr_term?.toLowerCase()) != -1 ||
            vonalkod.indexOf(arr_term?.toLowerCase()) != -1 ||
              (leiras && leiras?.indexOf(arr_term?.toLowerCase()) != -1)
      );
    });

    const allWords = (thisWord: string) => thisWord;
    // Every method will return true if all values are true in isWordThere.
    return isWordThere.every(allWords);
  }

  onSelectFocus(): void {
    if((this.torzs == 'kimeno-szamla' || this.torzs == 'nyugta') && this.helyesbites) {
      this.calcAktualisMennyisegHelyesbites();
    } else {
      this.fetchData.emit();
    }
  }

  // Searchbar mennyiség change
  private calcOnMennyisegChange(): void {
    this.mennyisegControl
      .valueChanges
        .pipe(
        //debounceTime(700),
        // TODO: Mennyiség figyelés
        map((mennyisegInput: string) => {
          const mennyiseg = mennyisegInput ? parseFloat(String(mennyisegInput).replace(',', '.')) : 0;

          if(String(mennyisegInput) == '-' && (this.torzs == 'szallitolevel' ||  this.torzs == 'bejovo-szamla' ||  (this.torzs == 'kimeno-szamla' && this.helyesbites))) {
            return mennyisegInput;
          } else if(Number.isNaN(mennyiseg)) {
            return null;
          }

          if(String(mennyisegInput).endsWith(',') || String(mennyisegInput).endsWith('.') || String(mennyisegInput).endsWith('-0') || mennyisegInput == null || mennyisegInput == '' || String(mennyisegInput).endsWith('.0') || String(mennyisegInput).endsWith(',0') ) {
            return mennyisegInput;
          }

          if(this.formValue.szallitolevel_id || this.formValue.kulsoraktarieladas_id && (this.torzs == 'kimeno-szamla' || this.torzs == 'nyugta')) {
            if(this.initialMennyiseg < mennyiseg) {
              this.service.showTulcsordultMennyisegToast('max');
              return this.initialMennyiseg;
            } else {
              return mennyiseg
            }
          }

          //Ha szolgáltatás akkor ne nézzünk mennyiséget
          if(this.getSelectedCikk()?.cikkcsoport_id == 57) {
            return mennyiseg;
          }

          const elerheto = ((this.getSelectedCikk()?.aktualis_mennyiseg - this.getSelectedCikk()?.foglalas) * this.service.calcOszto(this.formValue));

          const maxMennyiseg = 
            this.formValue.foglalas_id ? this.initialMennyiseg :
            elerheto >= 0 ?
            elerheto + (this.foglaltMennyiseg * this.service.calcOszto(this.formValue))
            : this.foglaltMennyiseg;

          const minMennyiseg = this.szamlazva;

          // Szállítólevél mennyiség nem lehet kisebb a kiszámlázottnál
          if(this.torzs == 'szallitolevel') {
            if(minMennyiseg && mennyiseg < minMennyiseg) {
              this.service.showTulcsordultMennyisegToast('min');
              return minMennyiseg;
            }
          }

          // Kimeno számla helyesbítés mennyiség nem lehet nagyobb a kiszámlázottnál
          if(this.torzs == 'kimeno-szamla' && this.helyesbites) {
            if(mennyiseg < -this.helyesbitesCikk.dataItem.mennyiseg) {
              this.service.showTulcsordultMennyisegToast('min');
              return -this.helyesbitesCikk.dataItem.mennyiseg;
            }
          }

          // Bevételezés és bejövő számla aktuális mennyiség figyelése szerkesztésnél
          if((this.torzs == 'bevetelezes' || this.torzs == 'bejovo-szamla') && this.editMode) {
            const keszleten = this.getSelectedCikk()?.aktualis_mennyiseg;
            if(keszleten < this.initialMennyiseg - mennyiseg) {
              this.service.showTulcsordultMennyisegToast('keszlet');
              return this.initialMennyiseg;
            }
          }

          // Túlmegy a készleten
          if (mennyiseg > maxMennyiseg && this.torzs != 'bevetelezes' && this.torzs != 'ajanlat' && this.torzs != 'elolegszamla' && this.torzs != 'bejovo-szamla' && this.torzs != 'egyeb-raktari-bevet' && this.torzs != 'dijbekero' && this.torzs != 'foglalas' && this.torzs != 'bejovo-koltseg') {
            // Info toast
            if(!this.helyesbites) {
              this.service.showTulcsordultMennyisegToast('max');
            }
            return maxMennyiseg;
          }

          if(mennyiseg < 0 && this.torzs == 'bejovo-szamla') {
            if((mennyiseg * -1) > maxMennyiseg) {
              this.service.showTulcsordultMennyisegToast('max');

              return 0;
            } else {
              return mennyiseg;
            }
          }

          // Van elég készlet
          else {
            return mennyiseg;
          }
        }),
        tap((mennyiseg: number) => {
          if(this.helyesbites && (this.torzs == 'kimeno-szamla' || this.torzs == 'nyugta')) {
            this.formGroup.patchValue(
              {
                uj_mennyiseg: mennyiseg.toString(),
              },
              { emitEvent: false }
            );
          }
          this.formGroup.patchValue(
            {
              mennyiseg,
            },
            { emitEvent: false }
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((mennyiseg: number) => {
        this.isMennyisegValid = true;
        const kedvezmeny = this.kedvezmeny ? 1 - (this.kedvezmeny.value / 100) : 1;

        const netto = this.selectedPenznem?.id == 1 ? 
            Math.round((this.nettoEgysegar?.value * mennyiseg) * kedvezmeny) : 
            (this.nettoEgysegar?.value * mennyiseg) * kedvezmeny;

        const afa = this.selectedPenznem?.id == 1 ? 
            Math.round(netto * (this.formValue.afakulcs / 100)) : 
            netto * (this.formValue.afakulcs / 100);

        this.formGroup.patchValue({
          netto_ar: netto,
          afa_ar: afa,
          brutto_ar: netto + afa,
          beszerzesi_ar_osszeg: this.formValue.beszerzesi_ar * mennyiseg
        });

        this.formGroup.markAsDirty();
      });
  }

  private calcOnNettoOsszesenChange(): void {
    if (this.nettoOsszesen) {
      this.nettoOsszesen.valueChanges
        .pipe(
          map((masked: string) => +masked),
          takeUntil(this.destroy$)
        )
        .subscribe((nettoOssz: number) => {
          const nettoAr = this.selectedPenznem?.id == 1 ? Math.round(nettoOssz) : nettoOssz;

          const afa_ar = this.selectedPenznem?.id == 1 ? Math.round(nettoAr * (this.afakulcs / 100)) : nettoAr * (this.afakulcs / 100);

          const bruttoAr = nettoAr + afa_ar;

          this.formGroup.patchValue(
            {
              netto_ar: nettoAr,
              afa_ar: afa_ar,
              brutto_ar: bruttoAr,
            },
            { emitEvent: false }
          );
        });
    }
  }

  calcOnNettoChanges(): void {
    this.bejovoSzamlaForm.get('netto_fix').valueChanges.subscribe(
      netto => {
        const afa = findByProp(this.afakulcsok,'id', this.formGroup.get('afakulcs_id')?.value)?.afakulcs;
        const brutto = netto * (1 + (afa / 100));

        this.bejovoSzamlaForm.patchValue({
          fix_ar: brutto
        }, {emitEvent: false})
      }
    )
  }

  calcOnBruttoChanges(): void {
    this.bejovoSzamlaForm.get('fix_ar').valueChanges.subscribe(
      brutto => {
        const afa = findByProp(this.afakulcsok,'id', this.formGroup.get('afakulcs_id')?.value)?.afakulcs;
        const netto = calcNetto(brutto, afa);

        this.bejovoSzamlaForm.patchValue({
          netto_fix: netto
        }, {emitEvent: false})
      }
    )
  }

  private calcOnKedvezmenyChange(): void {
    if (this.kedvezmeny) {
      this.kedvezmeny.valueChanges
        .pipe(
          map((masked: string) => {
          const maxKedvezmeny = this.kedvezmenyPartner?.kedvezmeny;
          const nettoEgysegar = this.selectedPenznem?.id == 1 ? Math.round(this.nettoEgysegar.value * (1 - (+masked / 100))) : this.nettoEgysegar.value * (1 - (+masked / 100)); 

            // Túlmegy a maximumon
          if (+masked > maxKedvezmeny) {
            // Info toast
            this.service.showTulcsordultMennyisegToast('max');

            return maxKedvezmeny;
          }
          //Ha alacsonyabb a beszerzési árnál
          else if (nettoEgysegar < this.formValue.beszerzesi_ar) {
            if(this.torzs == 'kimeno-szamla' && this.helyesbites) {
              return +masked;
            } else {
              this.service.showTulcsordultMennyisegToast('kedvezmeny');

              return 0;
            }
          }

          // Van elég készlet
          else {
            return +masked;
          }
          }),
          tap((kedvezmeny: number) => {
            this.formGroup.patchValue(
              {
                kedvezmeny,
              },
              { emitEvent: false }
            );
          }),
          takeUntil(this.destroy$)
        )
        .subscribe((kedvezmeny: number) => {
          const nettoEgysegar = this.selectedPenznem?.id == 1 ? Math.round(this.nettoEgysegar.value * (1 - (kedvezmeny / 100))) : this.nettoEgysegar.value * (1 - (kedvezmeny / 100)); 

          const netto = this.selectedPenznem?.id == 1 ? Math.round(this.nettoEgysegar?.value * this.mennyiseg) : this.nettoEgysegar?.value * this.mennyiseg;

          const nettoAr = this.selectedPenznem?.id == 1 ? Math.round(netto * (1 - (kedvezmeny / 100))) : netto * (1 - (kedvezmeny / 100));

          if(nettoEgysegar >= this.formValue.beszerzesi_ar) {
            this.formGroup.patchValue(
              {
                netto_ar: nettoAr,
              },
              { emitEvent: true }
            );
          }

        });
    }
  }

  private calcOnNettoChange(): void {
    if (this.nettoEgysegar) {
      this.nettoEgysegar.valueChanges
        .pipe(
          debounceTime(1000),
          map((masked: string) => {
            if(+masked < this.changeValuta(this.formValue.beszerzesi_ar) && this.torzs != 'bejovo-szamla') {
              this.service.showTulcsordultMennyisegToast('beszerar');

              this.formGroup.patchValue({
                netto_egysegar: null,
                brutto_egysegar: null
              }, {emitEvent: false});

              return null;
            } else {
              return +masked;
            }
          }),
          takeUntil(this.destroy$)
        )
        .subscribe((netto: number) => {
          console.log('netto change afakulcs', this.afakulcs);
          const kedvezmeny = this.kedvezmeny ? 1 - (this.kedvezmeny.value / 100) : 1;
          const bruttoEgysegar = this.arkategoriaId == 20 ? this.bruttoEgysegar?.value : calcBrutto(netto, this.afakulcs);
          //const nettoAr = netto * this.mennyiseg;
          //const bruttoAr = calcBrutto(netto * this.mennyiseg, this.afakulcs);
          const nettoAr = this.selectedPenznem?.id == 1 ? Math.round((this.nettoEgysegar?.value * this.mennyiseg) * kedvezmeny) : (this.nettoEgysegar?.value * this.mennyiseg) * kedvezmeny;

          const afa_ar = this.selectedPenznem?.id == 1 ? Math.round(nettoAr * (this.afakulcs / 100)) : nettoAr * (this.afakulcs / 100);

          const bruttoAr = nettoAr + afa_ar;

          this.formGroup.patchValue(
            {
              brutto_egysegar: bruttoEgysegar,
              afa_egysegar: bruttoEgysegar - netto,
              netto_ar: nettoAr,
              afa_ar: afa_ar,
              brutto_ar: bruttoAr,
            },
            { emitEvent: false }
          );
        });
    }
  }

  private calcOnBeszerarChange(): void {
      this.formGroup.get('beszerzesi_ar').valueChanges
        .pipe(
          map((masked: string) => +masked),
          takeUntil(this.destroy$)
        )
        .subscribe((beszerzesi_ar: number) => {
          
          this.formGroup.patchValue(
            {
              beszerzesi_ar_osszeg: beszerzesi_ar * this.formValue.mennyiseg
            },
            { emitEvent: false }
          );
        });
  }

  private calcOnNettoFixChange(): void {
    this.bejovoSzamlaForm?.get('netto_fix').valueChanges
      .pipe(
        map((masked: string) => +masked),
        takeUntil(this.destroy$)
      )
      .subscribe((netto_fix: number) => {
        const beszerar = this.bejovoSzamlaForm.get('beszerzesi_ar').value;

        if(netto_fix < beszerar) {
          this.service.showTulcsordultMennyisegToast('beszerar');
          this.bejovoSzamlaForm?.patchValue(
            {
              netto_fix: beszerar
            },
            { emitEvent: true }
          );
        }
      });
}

  private calcOnBeszerarOsszegChange(): void {
    this.formGroup.get('beszerzesi_ar_osszeg').valueChanges
      .pipe(
        map((masked: string) => +masked),
        takeUntil(this.destroy$)
      )
      .subscribe((beszerzesi_ar_osszeg: number) => {
        
        this.formGroup.patchValue(
          {
            beszerzesi_ar: (beszerzesi_ar_osszeg / +this.formValue.mennyiseg)
          },
          { emitEvent: false }
        );
      });
}

private calcOnMeChange(): void {
  this.formGroup.get('mennyisegi_egyseg').valueChanges
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe((mennyisegi_egyseg: any) => {
      const oszto = this.service.calcOszto(this.formValue);
      const netto = this.selectedPenznem?.id == 1 ? Math.round(this.calcByArkategoria(this.selectedCikk)) : this.calcByArkategoria(this.selectedCikk);
      this.formGroup.patchValue(
        {
          netto_egysegar: netto / oszto,
        },
        { emitEvent: true }
      );
    });
}

  private calcOnAfaChange(): void {
    if (this.afaEgysegar) {
      this.afaEgysegar.valueChanges
        .pipe(
          map((masked: string) => +masked),
          takeUntil(this.destroy$)
        )
        .subscribe((afa: number) => {
          //TODO: számolás
          const nettoEgysegar = calcNettoFromAfa(afa, this.afakulcs);
          const bruttoEgysegar = calcBrutto(nettoEgysegar, this.afakulcs);
          //const nettoAr = nettoEgysegar * this.mennyiseg;
          const nettoAr = this.selectedPenznem?.id == 1 ? Math.round(nettoEgysegar * this.mennyiseg) : nettoEgysegar * this.mennyiseg;
          const afa_ar = this.selectedPenznem?.id == 1 ? Math.round(nettoAr * (this.afakulcs / 100)) : nettoAr * (this.afakulcs / 100);
          const bruttoAr = nettoAr + afa_ar;

          this.formGroup.patchValue(
            {
              netto_egysegar: nettoEgysegar,
              brutto_egysegar: bruttoEgysegar,
              netto_ar: nettoAr,
              afa_ar: afa_ar,
              brutto_ar: bruttoAr,
            },
            { emitEvent: false }
          );
        });
    }
  }

  private calcOnBruttoChange(): void {
    if (this.bruttoEgysegar) {
      this.bruttoEgysegar.valueChanges
        .pipe(
          debounceTime(1000),
          map((masked: string) => {
            const netto = calcNetto(+masked, this.afakulcs);

            if(netto < this.formValue.beszerzesi_ar) {
              this.service.showTulcsordultMennyisegToast('beszerar');

              this.formGroup.patchValue({
                netto_egysegar: null,
                brutto_egysegar: null
              }, {emitEvent: false});

              return null;
            } else {
              return +masked;
            }
          }),
          takeUntil(this.destroy$)
        )
        .subscribe((bruttoEgysegar: number) => {
          const nettoEgysegar = calcNetto(bruttoEgysegar, this.afakulcs);
          const afaEgysegar = bruttoEgysegar - nettoEgysegar;
          //const nettoAr = nettoEgysegar * this.mennyiseg;
          const nettoAr = this.selectedPenznem?.id == 1 ? Math.round(nettoEgysegar * this.mennyiseg) : nettoEgysegar * this.mennyiseg;
          const afa_ar = this.selectedPenznem?.id == 1 ? Math.round(nettoAr * (this.afakulcs / 100)) : nettoAr * (this.afakulcs / 100);
          const bruttoAr = nettoAr + afa_ar;
          //const bruttoAr = calcBrutto(nettoAr, this.afakulcs);

          this.formGroup.patchValue(
            {
              netto_egysegar: nettoEgysegar,
              afa_egysegar: afaEgysegar,
              netto_ar: nettoAr,
              afa_ar: afa_ar,
              brutto_ar: bruttoAr,
            },
            { emitEvent: false }
          );
        });
    }
  }

  private calcOnAfakulcsChange(): void {
    this.formGroup
      .get('afakulcs_id')
      .valueChanges.pipe(
        //filter(() => this.torzs != 'bejovo-koltseg' ? this.nettoEgysegar?.value && this.bruttoEgysegar?.value : true),
        map((id: number) => this.getAfakulcs(id)),
        takeUntil(this.destroy$)
      )
      .subscribe((afakulcs: number) => {
        const nettoEgysegar = this.nettoEgysegar?.value;
        const bruttoEgysegar = calcBrutto(+nettoEgysegar, afakulcs);
        const nettoAr = this.selectedPenznem?.id == 1 ? Math.round(nettoEgysegar * this.mennyiseg) : nettoEgysegar * this.mennyiseg;

        const afa_ar = this.selectedPenznem?.id == 1 ? Math.round(nettoAr * (afakulcs / 100)) : nettoAr * (afakulcs / 100);

        const bruttoAr = nettoAr + afa_ar;
        //const bruttoAr = calcBrutto(nettoAr, this.afakulcs);

        this.formGroup.patchValue(
          {
            afakulcs,
            netto_egysegar: nettoEgysegar,
            brutto_egysegar: bruttoEgysegar,
            afa_egysegar: bruttoEgysegar - nettoEgysegar,
            netto_ar: nettoAr,
            afa_ar: afa_ar,
            brutto_ar: bruttoAr,
          },
          { emitEvent: false }
        );
      });
  }

  private getAfakulcs(afakulcsId: number): number {
    return findByProp(this.afakulcsok, 'id', afakulcsId)?.afakulcs;
  }

  private getCikkById(
    cikkId: number = this.formGroup.get('cikk_id')?.value
  ): any {
    if(this.cikktorzs && this.torzs != 'elolegszamla') {
      return findByProp(this.cikktorzs, 'id', cikkId);
    } else {
      return findByProp(this.cikkek, 'id', cikkId);
    }
    
  }

  private updateFoglalasTable() {
    this.foglalasService.selectFoglalasCikk(this.selectedItem.dataItem.cikk_id);
  }

  private focusMennyisegInput() {
    this.mennyisegInput.nativeElement.focus();
  }

  private fillToolbarOnEdit(editData: any) {
    this.formGroup.patchValue(
      {
        ...editData,
        kulsoraktarieladas_id: editData.kulsoraktarieladas_id || null ,
        szallitolevel_id: editData.szallitolevel_id || null,
        foglalas_id: editData.foglalas_id || null,
        bevetelezes_id: editData.bevetelezes_id || null,
        dijbekero_id: editData.dijbekero_id || null,
        elolegszamla_id: editData.elolegszamla_id || null,
        kimenoszamla_id: editData.kimenoszamla_id || null,
        eladasi_ar: editData?.eladasi_ar / editData.mennyiseg,
        beszerzesi_ar: editData?.beszerzesi_ar / editData.mennyiseg,
        beszerzesi_ar_osszeg: editData?.beszerzesi_ar
      },
      { emitEvent: false }
    );
  }

  private createEditData(cikk: Cikktorzs) {
    //this.calcArak(cikk);
    this.initialMennyiseg = this.selectedItem.dataItem.mennyiseg;
    this.szamlazva = this.selectedItem.dataItem.kiszamlazva;
    return {
      ...cikk,
      ...this.selectedItem.dataItem,
      aktualis_mennyiseg: cikk?.aktualis_mennyiseg || null,
    };
  }

  private calcNettoEgysegarByArkategoria(cikk: any) {
    if(this.arkategoriaId == 3) {
      return cikk.netto_fix ? cikk.netto_fix : 0;
    }
    else if(this.arkategoriaId == 20) {
      return cikk.webshop_ar? calcNetto(cikk.webshop_ar, this.afakulcs) : cikk.netto_fix ? cikk.netto_fix : 0;
    }
    else if(cikk.fix) {
      return cikk.fix_ar ? cikk.fix_ar : 0;
    } 
    else {
      return cikk.beszerzesi_ar * (1 + (this.arkategoriaSzazalek || 0) / 100);
    }
  }

  private getSelectedCikk(
    cikkId: number = this.formGroup.get('cikk_id')?.value
  ): Cikktorzs {
    return findByProp(this.cikkek, 'id', cikkId);
  }

  changeValuta(osszeg_ft: number): number {
    if(this.selectedPenznem && this.arfolyamok) {
      return this.service.calcValuta(this.arfolyamok, osszeg_ft, this.selectedPenznem?.id);
    } else {
      return osszeg_ft;
    }

  }

  onMeChange(id: number): any {

  }

  calcKulonbsegSzazalek(regi: number, uj: number): number {
    return (((uj / regi) - 1 ) * 100);
  }

  calcByArkategoria(cikk: any): number {
    if(this.arkategoriaId == 3) {
      return cikk.netto_fix ? cikk.netto_fix : 0;
    }
    else if(this.arkategoriaId == 20) {
      return cikk.webshop_ar? calcNetto(cikk.webshop_ar, this.afakulcs) : cikk.netto_fix ? cikk.netto_fix : 0;
    }
    else if(cikk.fix) {
      return cikk.fix_ar;
    } 
    else {
      return cikk.beszerzesi_ar * (1 + (this.arkategoriaSzazalek || 0) / 100)
    }
  }

  toMennyisegFocus(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      // Focus the cikk select input again after row added to list
      setTimeout(() => {
        this.mennyisegInput.nativeElement.focus();
      }, 0);
    }
  }

  private calcAktualisMennyiseg(): any {
    this.mennyisegByCikk.forEach(item => {
      const index = this.cikkek?.findIndex(cikk => cikk.id == item.cikk_id);
      const element = this.cikkek?.find(cikk => cikk.id == item.cikk_id);

      if(index > -1 && element) {
        this.cikkek[index] = {
          ...element,
          aktualis_mennyiseg: (element.aktualis_mennyiseg - item.osszesen) < 0 ? 0 : (element.aktualis_mennyiseg - item.osszesen)
        };
      }
    });
    
    if(this.cikkek) {
      this.cikkek = [...this.cikkek]
    }
  }

  private calcAktualisMennyisegHelyesbites(): any {
    this.mennyisegByCikk.forEach(item => {
      const index = this.cikkek?.findIndex(cikk => cikk.id == item.cikk_id);
      const element = this.cikkek?.find(cikk => cikk.id == item.cikk_id);

      if(index > -1 && element) {
        // Check if the initial quantity is stored
        if (!this.initialMennyisegByCikk.has(item.cikk_id)) {
          // Store the initial `aktualis_mennyiseg` in the map
          this.initialMennyisegByCikk.set(item.cikk_id, element.aktualis_mennyiseg);
        }

        const initialMennyiseg = this.initialMennyisegByCikk.get(item.cikk_id) || 0;

        this.cikkek[index] = {
          ...element,
          aktualis_mennyiseg: (initialMennyiseg - item.osszesen) < 0 ? 0 : (initialMennyiseg - item.osszesen)
        };
      }
    });
    
    if(this.cikkek) {
      this.cikkek = [...this.cikkek]
    }
  }

  private checkInput(tetel: any): boolean {
    const cikk = this.mennyisegByCikk.find(item => item.cikk_id == tetel.cikk_id);
    
    if(cikk) {
      return cikk.osszesen + cikk.eredetiOsszesen + tetel.mennyiseg >= 0;
    } else {
      return true;
    }
  }

  private calcArak(cikk: any) {
    this.arakByArkategoria = []
    this.arkategoriak.forEach(arkat => {
      if(arkat.id != 20) {
        this.arakByArkategoria.push({
          arkategoria_id: arkat.id,
          ar: Math.round(this.calcArByArkategoria(cikk, arkat) * 100) / 100
        })
      }
    });
  }

  private calcArByArkategoria(cikk: any, arkategoria: any) {
    if(arkategoria.id == 3) {
      return cikk?.netto_fix ? this.changeValuta(cikk.netto_fix) : 0;
    }
    else if(arkategoria.id == 20) {
      return cikk?.webshop_ar ? cikk.webshop_ar : cikk.netto_fix ? cikk.netto_fix : 0;
    }
    else if(cikk?.fix) {
      return this.changeValuta(cikk.fix_ar);
    } 
    else {
      return this.changeValuta(cikk?.beszerzesi_ar * (1 + (arkategoria.ertek || 0) / 100));
    }
  }

  private getTetelArkategoria() {
    let arkat_id = 1;
    if(this.arkategoriaId == 20) {
      arkat_id = 20
    } else {
      const netto_egysegar = this.normalizeNettoEgysegar(this.formValue);

      this.arakByArkategoria?.forEach(arkategoria => {
        if(roundToTwoDecimalPlaces(arkategoria.ar) == roundToTwoDecimalPlaces(netto_egysegar)) {
          arkat_id = arkategoria.arkategoria_id;
        }
      })
    }

    return arkat_id;
  }

  private normalizeNettoEgysegar(cikk: any): number {
    if(cikk.mennyisegi_egyseg != cikk.me_id) {
      if(cikk.mennyisegi_egyseg == cikk.me_id_2) {
        return cikk.netto_egysegar / cikk.me2_oszto;
      } else if(cikk.mennyisegi_egyseg == cikk.me_id_3) {
        return cikk.netto_egysegar / cikk.me3_oszto;
      } else {
        return cikk.netto_egysegar;
      }
    } else {
      return cikk.netto_egysegar;
    }
  }

  private patchHelyesbitoCikk(cikk: any): any {
    this.calcArak(cikk);
    this.formGroup.patchValue({
      ...cikk,
      tid: null,
      kapott_id: null,
      szallitolevel_id: null,
      kulsoraktarieladas_id: null,
      foglalas_id: null,
      bevetelezes_id: null,
      dijbekero_id: null,
      elolegszamla_id: null,
      kimenoszamla_id: null,
    }, {emitEvent: false});
  }

  private checkIfNotInList(cikk_id: number): boolean {
    const index = this.tetelek.findIndex(tetel => tetel.cikk_id == cikk_id && tetel.szallitolevel_id);

    return index < 0 ? true : false;
  }
}
