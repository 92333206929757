// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  apiUrl: 'https://aranyi.frederik-technologies.com/api',
  baseUrl: 'https://aranyi.ftech.hu',
  bizonylatkereso: 'service/rendeles/bizonylatkereso',
  auth: 'auth',
  appVersion: require('../../package.json').version,
  KIRAuth: 'https://kir.frederik-technologies.com/auth',
  KIRCegkereso:
    'https://kir.frederik-technologies.com/service/basedata/cegkereso',
  uploads: 'https://aranyi.frederik-technologies.com/print',
  pdfGeneralas: 'service/nyomtatas',
  retryNAVApi: 'service/helplib/szamla_ujrakuldese',
  szamlaStatusApi: 'service/helplib/szamla_statusz',
  leltarLezarasApi: 'service/helplib/leltarLezarasa',
  beszerarModositasApi: 'service/helplib/beszerarModositas',
  emailKuldesApi: 'service/emailkuldes/kuldes',
  fileUpload: 'service/fileslib/upload',
  uploadedFiles: 'writable/uploads/',
  leltarCikkMennyiseg: 'service/helplib/cikkMennyiseg',
  feladatOlvasott: 'service/helplib/feladat_olvasott',
  penztarNyomtatas: 'service/nyomtatas/penztar',
  xlsGeneralas: 'service/xls_generalas'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
