import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Cikktorzs, Column, RaktarkeszletByRaktar } from '../models/torzsek';
import { findByProp } from '../utils';
import { MennyisegiEgysegekService } from './mennyisegi-egysegek.service';
import { CikkcsoportokService } from './cikkcsoportok.service';

@Injectable({
  providedIn: 'root',
})
export class RaktarkeszletService {
  private readonly apiEndpoint = 'raktarkeszlet';
  private readonly keszletkartonApi = 'raktarkeszlet/keszletkarton';
  private readonly foglalasokEndpoint = 'service/frissiteslib/foglalt_cikkek';

  constructor(
    private api: ApiService,
    private meService: MennyisegiEgysegekService,
    private cikkcsoportService: CikkcsoportokService
  ) {}

  get columns$(): Observable<Column[]> {
    const columns: Column[] = [
      //{ name: 'id', nullable: true },
      { name: 'cikkszam', nullable: true},
      { name: 'cikkcsoport', nullable: true},
      { name: 'megnevezes', nullable: true },
      { name: 'aktualis_mennyiseg', nullable: true, hidden: true },
      { name: 'aktualis_mennyiseg_text', nullable: true },
      { name: 'mennyisegi_egyseg', nullable: true, hidden: true },
      { name: 'utolso_mozgas', nullable: true },
    ];

    return of(columns);
  }

  get keszletkartonColumns$(): Observable<Column[]> {
    const columns: Column[] = [
      { name: 'bizonylat_szam', nullable: true },
      { name: 'partner_nev', title: 'Partner', nullable: true },
      { name: 'egysegar', title: 'Egységár', nullable: true, type: 'currency', suffix: 'Ft' },
      {
        name: 'mennyiseg',
        nullable: true,
        type: 'currency',
        suffixProp: 'mennyisegiegyseg_rovidnev',
      },
      { name: 'letrehozva', nullable: true, type: 'date' },
    ];

    return of(columns);
  }

  fetchRaktarkeszlet(raktarId: number): Observable<any[]> {
    return forkJoin([
      this.api.get(`${this.apiEndpoint}/${raktarId}`),
      this.meService.initData$,
      this.cikkcsoportService.initData$
    ]).pipe(
      map(([data, mek, cikkcsoportok]) =>
        data.map((e: any) => ({
          ...e,
          id: e.cikk_id,
          aktualis_mennyiseg_text: `${e.aktualis_mennyiseg.toLocaleString()} ${
            findByProp(mek, 'id', e?.mennyisegiegyseg_id)?.rovidnev
          }`,
          cikkcsoport: findByProp(cikkcsoportok, 'id', e.cikkcsoport_id)?.nev
        }))
      ),
      // tap((data: any[]) => this._data.next(data)),
      catchError((err: any) => {
        console.log(err);
        return of([]);
      })
    );
  }

  fetchKeszletkarton(raktarId: number, cikkId: number): Observable<any> {
    return this.api.get(`${this.keszletkartonApi}/${raktarId}/${cikkId}`).pipe(
      map((data: any) => 
        data.map(item => {
          if(!item.partner_nev) {
            return {...item, partner_nev: 'Névtelen vásárló'};
          } else {
            return item;
          }
        })
      ),
      catchError((err: any) => {
        console.log(err);
        return of([]);
      })
    );
  }

  fetchRaktarkeszletById(
    raktarId: number
  ): Observable<RaktarkeszletByRaktar[]> {
    if(raktarId) {
      return this.api.get(`${this.apiEndpoint}/raktar/${raktarId}`).pipe(
        catchError((err) => {
          console.log(err);
  
          return of([]);
        })
      );
    } else {
      return of([]);
    }
  }

  fetchFoglalasById(
    oe_id_param?: number
  ): Observable<any> {
    const oe_id = JSON.parse(localStorage.getItem('currentUser'))?.oe;
    if(oe_id) {
      return this.api.get(`${this.foglalasokEndpoint}/${oe_id}`).pipe(
        catchError((err) => {
          console.log(err);
  
          return of([]);
        })
      );
    } else {
      return of([]);
    }
  }
}
